<script>
import {GET_SPEAKING} from "../../../../core/services/store/customer/testInput.module";
import {
  STATUS_FAlURE, STATUS_NOT_ACTIVE,
  STATUS_OVER_LOADING,
  STATUS_PENDING,
  STATUS_TO_DO
} from "../../../../core/config/testInput/testInputOption";

export default {
  name: "LoadingCreateTestInput",
  data(){
    return{
    timePending:60,
    methodCountDown:null
    }
  },
  props:{
   idTaskCreating:{
     type:Number,
     default(){
       return null
     }
   },
    countTime:{
      type:Number,
    }
  },
  mounted() {
    this.timePending = this.countTime ?? 60
    this.CountDownTimePending()
    },
  methods:{
    CountDownTimePending(){
      this.checkStatusCreateTestInput()
       this.methodCountDown = setInterval(()=>{
         if(this.timePending <= 0){
           clearInterval(this.methodCountDown)
           return
         }
         this.timePending -= 1
       },1000)
    },
    checkStatusCreateTestInput(){
      this.$store.dispatch(GET_SPEAKING,this.idTaskCreating).then((data)=>{
				let dataResponse = data?.data;
				let status = dataResponse?.status;
				let customerId = +data?.data?.customer_id;
        if(+data?.data?.status === STATUS_PENDING && this.timePending > 0){
          setTimeout(()=>this.checkStatusCreateTestInput(),2000)
          return;
        }
				//Đoạn này code hơi khó hiểu nha Nguyên.
        //Oke anh để sau em tối ưu lại.
        if(status === STATUS_FAlURE || (status === STATUS_TO_DO && dataResponse.error_code !== 1)) {
          this.$emit('statusCreateTestInput',+data?.data?.status,+data?.data?.customer_id,dataResponse)
          clearInterval(this.methodCountDown)
          return;
        }
				if (status === STATUS_TO_DO && dataResponse.error_code === 1) {
						this.$emit('statusCreateTestInput',STATUS_FAlURE, customerId,dataResponse)
				}
        if(+data?.data?.status === STATUS_PENDING && this.timePending === 0){
          this.$emit('statusCreateTestInput',STATUS_OVER_LOADING)
          clearInterval(this.methodCountDown)
        }
        if(status === STATUS_NOT_ACTIVE){
          this.$emit('statusCreateTestInput',STATUS_FAlURE)
          clearInterval(this.methodCountDown)
        }
      }).catch(()=>{
        this.$emit('statusCreateTestInput',STATUS_FAlURE)
        clearInterval(this.methodCountDown)
      })
    }
  }
}
</script>

<template>
<div class="text-center">
  <img src="../../../../../public/media/loadingTestInput.png" alt="">
  <h1 style="font-size: 13px" class="mt-3">Hệ thống đang trong quá trình xử lý. Vui lòng chờ trong giây lát.</h1>
  <h1 class="mt-3" style="color:#5E00B6;
">{{timePending}}s</h1>
</div>
</template>

<style scoped>
img {
  width: 50px; /* Điều chỉnh kích thước phù hợp */
  height: 50px; /* Điều chỉnh kích thước phù hợp */
  position: relative;
}

img {
  width: 4%;
  height: 4%;
  animation: rotate 4s linear infinite; /* Thiết lập hiệu ứng quay vô hạn */
}

@keyframes rotate {
  0% {
    transform: rotate(0deg); /* Bắt đầu quay từ góc 0 độ */
  }
  50% {
    transform: rotate(360deg); /* Quay một vòng đầy (360 độ) */
  }
  100% {
    transform: rotate(720deg); /* Quay hai vòng đầy, tức là 720 độ */
  }
}
</style>